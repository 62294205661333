<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-02-14 11:50:50
 * @ Description: 营销管理>标签分组>用户分组>用户分组详情
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="content">
      <ykc-detail-plus class="box-card">
        <div slot="header" class="clearfix">
          <span>投资方信息</span>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="投资方名称" :labelWidth="labelWidth">
            <span>{{ dealData(formData.investorName) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="运营商ID" :labelWidth="labelWidth">
            <span>{{ dealData(formData.operatorId) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="设备所属方ID" :labelWidth="labelWidth">
            <span>{{ dealData(formData.deviceId) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="开票名称" :labelWidth="labelWidth">
            <span>{{ dealData(formData.invoicingName) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="联系人" :labelWidth="labelWidth">
            <span>{{ dealData(formData.contacts) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="联系人电话" :labelWidth="labelWidth">
            <span>{{ dealData(formData.contactsPhone) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="公司地址" :labelWidth="labelWidth">
            <span>{{ dealData(formData.companyAddress) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="公司电话" :labelWidth="labelWidth">
            <span>{{ dealData(formData.companyPhone) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="收款银行" :labelWidth="labelWidth">
            <span>{{ dealData(formData.bank) }}</span>
          </ykc-detail-item-plus>
        </div>
        <div class="flexBox">
          <ykc-detail-item-plus label="开票地址" :labelWidth="labelWidth">
            <span>{{ dealData(formData.invoicingAddress) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="税号" :labelWidth="labelWidth">
            <span>{{ dealData(formData.taxNo) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="微信商户">
            <span>{{ dealData(formData.mchName) }}</span>
          </ykc-detail-item-plus>
        </div>
      </ykc-detail-plus>
    </div>
  </scroll-layout>
</template>

<script>
  import { investmentData } from '@/service/apis';
  import { dealData } from '../../../../utils/index';

  export default {
    name: 'InvestmentDetail',
    components: {},

    data() {
      return {
        id: '',
        labelWidth: '80',
        formData: {},
        pageButtons: [
          {
            id: '0',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
      };
    },
    computed: {},
    created() {
      const { id } = this.$route.query;
      this.id = id;
      this.queryDetail();
    },
    methods: {
      dealData,
      queryDetail() {
        // 编辑回显
        if (this.id) {
          investmentData
            .investmentDetail({ id: this.id })
            .then(res => {
              this.formData = res;
            })
            .catch(() => {});
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    .box-card {
      width: 100%;
      height: auto;
      margin: 10px 0px 0px 0px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .flexBox {
        display: flex;
        margin-bottom: 20px;
      }
      .status {
        margin-left: 10px;
        color: #fff;
        height: 20px;
        border-radius: 10px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        line-height: 20px;
        &.stationStatus {
          &.status1000135 {
            background-color: #009f09;
          }
          &.status1000136 {
            background-color: #e81b1b;
          }
        }
        &.infoStatus {
          &.status1 {
            background-color: #009f09;
          }
          &.status2 {
            background-color: #ccc;
            color: #fff;
          }
          &.status0 {
            background-color: #e81b1b;
          }
        }
      }
      .title {
      }
    }
  }
</style>
